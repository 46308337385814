<template>
  <div>
    <c-search-box ref="searchbox" :isShowSearch="false" @enter="getList">
      <template slot="search">
        <div class="col-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @datachange="getList"/>
        </div>
        <div class="col-6">
          <c-dept type="search" name="deptCd" :plantCd="plantCd" v-model="searchParam.deptCd" @datachange="getList" />
        </div>
        <div class="col-6" v-if="popupParam.isDeptManager">
          <c-checkbox
            :isFlag="true"
            label="관리감독자"
            name="deptManagerFlag"
            v-model="searchParam.deptManagerFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="userTable"
      title="사용자 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="userId"
      :filtering="true"
      :columnSetting="false"
      :isFullScreen="false"
    >
    </c-table>
    <div class="space-bottom-button"></div>
    <!-- 버튼 영역 -->
    <div class="search-box-btn">
      <q-btn-group outline >
        <c-btn label="선택" icon="check" color="teal-5" @btnClicked="select" />
      </q-btn-group>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'user-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          isDeptManager: false,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '성명',
            align: 'center',
            sortable: true
          },
          {
            name: 'jobName',
            field: 'jobName',
            label: '직책',
            align: 'center',
            sortable: true
          },
          {
            name: 'spotName',
            field: 'spotName',
            label: '직위',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '400px'
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        keyword: '',
        deptManagerFlag: 'N',
        retireFlag: 'N',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    plantCd() {
      return this.searchParam.plantCd || this.popupParam.plantCd
    }
  },
  watch: {
    contentHeight() {
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.user.list.url
      // code setting
      // list setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      if (this.popupParam.isDeptManager) {
        this.searchParam.deptManagerFlag = 'Y';
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['userTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '사용자를 지정하세요.', // 사용자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
